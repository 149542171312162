import { GetStaticProps, NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RichText } from 'prismic-reactjs';
import { useScroll, motion, useTransform } from 'framer-motion';
import { Layout, SeoMeta } from '../components/Layout';
import { Client } from '../utils';
import { Section } from '../components/Sections';
import { Button } from '../components/Buttons';
import { Slider } from '../components/Slider';
import { Icon } from '../components/Icon';

interface Content {
  content: any;
}

const Home: NextPage<Content> = ({ content }) => {
  return (
    <Layout>
      <SeoMeta
        title={content.seo__title && RichText.asText(content.seo__title)}
        desc={
          content.seo__description && RichText.asText(content.seo__description)
        }
        socialImage={content.seo__image}
      />

      <div className="pin-spacer">
        <div className="hero hero--frontpage">
          <div>
            <div className="hero__inverted-border-radius" />

            {/* <div className="intro-header">
              <h1
                className={`${'font--xxl'} ${'font--primary'} ${'font--light'}`}
              >
                Phase 2
              </h1>

              <p className={`${'font--m'} ${'font--light'}`}>
                Applications for Astoria West Lottery Phase 2 Are Now Closed
              </p>
            </div> */}
          </div>

          <div className="hero--frontpage__background"></div>
        </div>
      </div>

      <div style={{ backgroundColor: '#eecba9' }}>
        <Section hasTop>
          <Container fluid>
            <Row>
              <Col md={6} className={`${'flex flex--align-end'}`}>
                <h1 className={`${'font--xxl'} ${'font--primary'}`}>
                  {content.headline && RichText.asText(content.headline)}
                </h1>
              </Col>
              <Col
                md={{
                  size: 6,
                  offset: 0,
                }}
                lg={{
                  size: 4,
                  offset: 2,
                }}
              >
                <p>{content.text && RichText.asText(content.text)}</p>
                <div className="m-t">
                  <Button title="View amenities" href="/amenities" />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section>
          <Container fluid>
            <Row>
              <Col
                md={6}
                // lg={ 4 }
                className="flex flex--align-center"
              >
                <p>{content.text1 && RichText.asText(content.text1)}</p>
              </Col>
            </Row>
          </Container>
        </Section>

        {content.slider.length > 0 && (
          <Slider slides={content.slider} roundedImages size="xl" hasBottom />
        )}

        {/* <Section>
          <Container fluid>
            <Row>
              {content.slider[0].slider__image &&
                content.slider[0].slider__image.url && (
                  <Col
                    md={{
                      size: 8,
                      offset: 0,
                    }}
                    // lg={{ size: 6, offset: 2 }}
                  >
                    <div className="m-t--sm">
                      <div className="container--border-radius container--offset-right">
                        <Image
                          src={content.slider[0].slider__image.url}
                          alt={content.slider[0].slider__image.alt}
                          layout="responsive"
                          blurDataURL={content.slider[0].slider__image.url}
                          placeholder="blur"
                          width={
                            content.slider[0].slider__image.dimensions.width
                          }
                          height={
                            content.slider[0].slider__image.dimensions.height
                          }
                        />
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </Container>
        </Section> */}

        <Section>
          <Container fluid>
            <Row>
              <Col
                md={{
                  size: 8,
                  offset: 4,
                }}
              >
                <Row>
                  <Col md={6}>
                    <p>{RichText.asText(content.textfield_left)}</p>
                  </Col>
                  <Col md={6}>
                    <div className="m-t--sm">
                      <p>{RichText.asText(content.textfield_right)}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section>
          <Container fluid>
            <Row>
              <Col md={6} className={`${'flex flex--align-end'}`}>
                <div>
                  <h1
                    className={`${'font--xxl'} ${'font--primary'}`}
                    style={{ margin: 0 + 'px' }}
                  >
                    {'Beyond rent Discounts'}
                  </h1>
                  <p
                    className={`${'font--s'} ${'font--bold'}`}
                    style={{ margin: 0 + 'px' }}
                  >
                    {'lottery apartments come with:'}
                  </p>
                </div>
              </Col>
            </Row>

            <div className={'benefits'}>
              <div className="benefit">
                <Icon type={'piggy-bank'} />
                <div className="benefit__text-container">
                  <p>No Security Deposit Required</p>
                </div>
              </div>

              <div className="benefit">
                <Icon type={'garden'} />
                <div className="benefit__text-container">
                  <p>
                    Free access to select amenities for one full year (See FAQ
                    page for details)
                  </p>
                </div>
              </div>

              <div className="benefit">
                <Icon type={'gallery'} />
                <div className="benefit__text-container">
                  <p>
                    Up to $1,000 reimbursed for moving expenses on a 2 year
                    lease
                  </p>
                </div>
              </div>

              <div className="benefit">
                <Icon type={'wifi'} />
                <div className="benefit__text-container">
                  <p className={`${'font--m'} `}>
                    Free internet service with select providers for 6 months
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Section> */}
      </div>
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const res = await Client.query('[at(document.type, "frontpage")]');
  const data = res.results.map((content: { data: any }) => {
    return content.data;
  });
  const content = data[0];

  const gallery = res.results.map((gallery: { data: any }) => {
    const filteredImages = gallery.data;
    return filteredImages;
  });

  return {
    props: {
      content,
      gallery,
    },
  };
};
