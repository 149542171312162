import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { Section } from '../Sections';
import { RichText } from 'prismic-reactjs';
import { FC } from 'react';

interface Props {
  slides?: Array<Slide>;
  slide?: Array<Slide>;
  roundedImages?: boolean;
  aboveTheFold?: boolean;
  size?: string;
  isFreeMode?: boolean;
  hasBottom?: boolean;
  hasTop?: boolean;
}

interface Slide {
  slider__title?: any;
  slider__image: {
    url: string;
    alt: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
}

SwiperCore.use([Navigation]);

export const Slider: FC<Props> = ({
  slides,
  roundedImages,
  size,
  isFreeMode = false,
  hasBottom = false,
  hasTop = false,
}) => {
  const slideClass = classNames('slide', {
    ['slide--rounded']: roundedImages,
  });

  return (
    <Section hasBottom={hasBottom} hasTop={hasTop}>
      <Container fluid>
        {slides && (
          <>
            <Swiper
              className="slider-container"
              spaceBetween={10}
              slidesPerView={isFreeMode ? 'auto' : 1.25}
              lazy={false}
              preloadImages={false}
              navigation
              freeMode={true}
              grabCursor
              breakpoints={{
                576: {
                  slidesPerView: isFreeMode ? 'auto' : 1.5,
                },
                992: {
                  slidesPerView: isFreeMode
                    ? 'auto'
                    : size === 'xl' || size === 'xxl'
                    ? 1.5
                    : 2.5,
                },
                1600: {
                  slidesPerView: isFreeMode
                    ? 'auto'
                    : size === 'xl'
                    ? 2.5
                    : size === 'xxl'
                    ? 1.5
                    : 3.5,
                },
              }}
            >
              {slides.length > 0 &&
                slides.map((slide, key) => {
                  if (!slide.slider__image?.dimensions) {
                    return null;
                  }

                  return (
                    <SwiperSlide
                      style={{
                        // if isFreeMode, than make landscape images wider than portrait
                        width: !isFreeMode
                          ? 'auto'
                          : slide.slider__image.dimensions.width >
                            slide.slider__image.dimensions.height
                          ? '70%'
                          : '35%',
                        maxWidth: !isFreeMode
                          ? 'auto'
                          : slide.slider__image.dimensions.width >
                            slide.slider__image.dimensions.height
                          ? '80%'
                          : '40%',
                      }}
                      key={key}
                      className={slideClass}
                    >
                      <Image
                        src={slide.slider__image.url}
                        alt={slide.slider__image.alt}
                        width={slide.slider__image.dimensions.width}
                        height={slide.slider__image.dimensions.height}
                        blurDataURL={slide.slider__image.url}
                        layout="responsive"
                        placeholder="blur"
                      />
                      {slide.slider__title &&
                        RichText.asText(slide.slider__title) && (
                          <p className="font--lg font--bold slide__title">
                            {RichText.asText(slide.slider__title)}
                          </p>
                        )}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </>
        )}
      </Container>
    </Section>
  );
};
